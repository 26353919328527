import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import Link from "next/link";

import PropertyCard from "shared/components/property-card";
import CustomCarrusel from "shared/components/custom-carrusel";
import PropertyCardCollectionSkeleton from "shared/components/skeletons/property-collector-card.skeleton";
import { IRealestateResponseData } from "shared/models/interfaces/response/realestate.interface";
import {
  getRealestate,
  IRealestateFilters,
} from "api/realestate.request";
import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";
import useWindowSize from 'custom-hooks/useWindowsSize.hook';


function PropertiesSlider(props: {
  title: string;
  subtitle: string;
  filters: IRealestateFilters;
  refRequest: string;
  detailPath: (x: IRealestateResponseData) => string;
  children?: JSX.Element;
  withoutResultsComponent?: JSX.Element;
}) {
  const size = useWindowSize();
  const width: any = size?.width;
  const ref =  useGetPersistParams()
  const fetchProjectSlider = async () => {
    const response = await getRealestate({ page: 1, ...props.filters });
    return response;
  };

    
  const { data, status, mutate } = useMutation(
    [props.refRequest],
    fetchProjectSlider,
    {}
  );

  useEffect(() => {
    mutate();
  }, []);

  if (status === "loading") {
    return (
      <Section title={props.title} subtitle={props.subtitle}>
        {[]}
      </Section>
    );
  }

  if (data?.data.length == 0) {
    return <>{props.withoutResultsComponent ?? []}</>;
  }

  if (status === "error") {
    return null;
  }

  return (
    <>
      <Section title={props.title} subtitle={props.subtitle}>
        {data?.data.map((property, i) => (
          <a key={i} href={props.detailPath(property) + ref} target={width > 991 ? "_blank" : ""} rel="noreferrer" >
            <CardWrapper>
              <PropertyCard {...property} />
            </CardWrapper>
          </a>
        ))}
      </Section>
      {props.children}
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  margin-bottom: 50px;

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
    padding-bottom: 30px;
    /*background: var(--white);*/
  }
`;

const CardWrapper = styled.a`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  margin: 5px;
`;

const Title = styled.h2`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.9rem;
  margin-bottom: 20px;
  color: var(--color-blue-main);
  margin-top: 50px;
`;

const TitleSpan = styled.span`
  font-weight: 300;
`;

export default PropertiesSlider;

function Section(props: {
  children: React.ReactNode;
  title: string;
  subtitle: string;
}) {
  const { children, subtitle, title } = props;

  return (
    <Wrapper>
      <div className="container">
        <Title>
          {title} <TitleSpan>{subtitle}</TitleSpan>
        </Title>
        <CustomCarrusel
          enablePlaceholder={true}
          placeholderItem={<PropertyCardCollectionSkeleton />}
          numberOfPlaceholderItems={4}
        >
          {children}
        </CustomCarrusel>
      </div>
    </Wrapper>
  );
}
